<template>
  <div class="column content-box">
    <report-filter-selector
      :show-business-hours-switch="false"
      @date-range-change="onDateRangeChange"
    />
    <woot-button
      color-scheme="success"
      class-names="button--fixed-right-top"
      icon="arrow-download"
      @click="downloadReports"
    >
      {{ $t('ANALYTICS.DOWNLOAD') }}
    </woot-button>
  </div>
</template>

<script>
import ReportFilterSelector from './components/FilterSelector';
import { mapGetters } from 'vuex';
import { generateFileName } from '../../../../helper/downloadHelper';

export default {
  name: 'AnalyticsReport',
  components: {
    ReportFilterSelector,
  },
  data() {
    return { pageIndex: 1, from: 0, to: 0 };
  },
  computed: {
    ...mapGetters({}),
  },
  mounted() {},
  methods: {
    onDateRangeChange({ from, to }) {
      this.from = from;
      this.to = to;
    },
    downloadReports() {
      this.$store.dispatch('csat/downloadCSATReports', {
        type: 'analytics',
        from: this.from,
        to: this.to,
        user_ids: this.userIds,
        fileName: generateFileName({ type: 'analytics', to: this.to }),
      });
    },
  },
};
</script>
