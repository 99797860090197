/* eslint no-console: 0 */
/* global axios */
/* eslint no-undef: "error" */

export default {
  authenticateFlow() {
    const helperUrl = window.location.origin + '/helper/v1/bearer/admin';

    const fetchPromise = new Promise((resolve, reject) => {
      axios
        .get(helperUrl, {})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },
};
